<template>
  <Scaffold>
    <template #buttons>
      <Button @click="onSubmit" type="primary">변경</Button>
    </template>

    <div class="page-content">
      <Form>
        <FormItem label="현재 비밀번호">
          <Input size="large" type="password" v-model="form.password"></Input>
        </FormItem>
        <FormItem label="새 비밀번호">
          <Input size="large" type="password" v-model="form.new_password"></Input>
        </FormItem>
        <FormItem label="비밀번호 확인">
          <Input size="large" type="password" v-model="form.password_re"></Input>
        </FormItem>
      </Form>
    </div>
  </Scaffold>
</template>

<script>
export default {
  data() {
    return {
      form: {
        password: '',
        new_password: '',
        password_re: '',
      },
      requires: ['password', 'new_password', 'password_re']
    }
  },
  methods: {
    onSubmit() {
      if (!this.checkRequired(this.form, this.requires)) {
        this.messages.error('모든 값을 입력해야 합니다.')
        return ;
      } else if (this.form.new_password !== this.form.password_re) {
        this.messages.error('새로운 비밀번호가 일치하지 않습니다.')
        return;
      }
      this.$axios.$post('auth/pass/change', this.form).then(res => {
        this.messages.success('비밀번호가 변경되었습니다.')
        this.form = {
          password: '',
          new_password: '',
          password_re: '',
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
